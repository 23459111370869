<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <user v-bind:id="userId" v-bind:my-account="myAccount" v-bind:save-handler="saveHandler"></user>
            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import User from "@/components/app/user/User";

    export default {
        name: 'User_view',
        data: function(){
            return {
                pageName: 'User',
                segments: [
                    { link: true, text: 'Users', routeName: 'App.Users' },
                    { link: false, text: 'User' },
                ],
                userId: 0,
                myAccount: false,
            }
        },
        components: {
            User,
            BreadCrumb
        },
        props: {
        },
        computed: {

        },
        methods:{
            saveHandler: async function () {
                await this.$router.push({name: 'App.Users'});
            },
        },
        async created() {
            this.userId = this.$route.params.id;
            console.log('user view created: ', this.userId);
        }
    }
</script>

<style scoped>

</style>
